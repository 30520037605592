export const firstLineColour = "#804EEC";
export const secondLineColour = "#ED6319";
export const thirdLineColour = "#FFCC00";

export const chartSettings = {
  width: 1000,
  height: 500,
  maxWidth: 1000,
  maxHeight: 500,
  paddingLeft: 75,
  paddingRight: 75,
  paddingTop: 115,
  paddingBottom: 40,
  margin: 0,
  textMarginTop: 50,
  textMarginLeft: 50,
  fontSize: 20,
};

export const smallChartSettings = {
  width: 1000 / 2,
  height: 800 / 2,
  maxWidth: 1000 / 2,
  maxHeight: 800 / 2,
  paddingLeft: 75,
  paddingRight: 20,
  paddingTop: 50,
  paddingBottom: 40,
  margin: 10,
  textMarginTop: 25,
  textMarginLeft: 20,
};

export const langCountry = window.navigator.language;
