const allConversionEvents = [
  [
    "app_custom_event.fb_mobile_achievement_unlocked",
    "Mobile App Feature Unlocks",
  ],
  ["app_custom_event.fb_mobile_activate_app", "Mobile App Starts"],
  ["app_custom_event.fb_mobile_add_payment_info", "Mobile App Payment Details"],
  ["app_custom_event.fb_mobile_add_to_cart", "Mobile App Adds To Cart"],
  ["app_custom_event.fb_mobile_add_to_wishlist", "Mobile App Adds to Wishlist"],
  [
    "app_custom_event.fb_mobile_complete_registration",
    "Mobile App Registrations",
  ],
  ["app_custom_event.fb_mobile_content_view", "Mobile App Content Views"],
  ["app_custom_event.fb_mobile_initiated_checkout", "Mobile App Checkouts"],
  ["app_custom_event.fb_mobile_level_achieved", "Mobile App Achievements"],
  ["app_custom_event.fb_mobile_purchase", "Mobile App Purchases"],
  ["app_custom_event.fb_mobile_rate", "Mobile App Ratings"],
  ["app_custom_event.fb_mobile_search", "Mobile App Searchs"],
  ["app_custom_event.fb_mobile_spent_credits", "Mobile App Credit Spends"],
  [
    "app_custom_event.fb_mobile_tutorial_completion",
    "Mobile App Tutorial Completions",
  ],
  ["app_custom_event.other", "Other Mobile App Actions"],
  ["app_install", "App Installs"],
  ["app_use", "App Uses"],
  ["checkin", "Check-ins"],
  ["comment", "Post Comments"],
  ["credit_spent", "Credit Spends"],
  ["games.plays", "Game Plays"],
  ["landing_page_view", "Landing Page Views"],
  ["like", "Page Likes"],
  ["link_click", "Link Clicks"],
  ["mobile_app_install", "Mobile App Installs"],
  [
    "offsite_conversion.custom.<custom_conv_id>",
    "Custom Conversions defined by the advertiser",
  ],
  ["offsite_conversion.fb_pixel_add_payment_info", "Adds Payment Info"],
  ["offsite_conversion.fb_pixel_add_to_cart", "Adds To Cart"],
  ["offsite_conversion.fb_pixel_add_to_wishlist", "Adds To Wishlist"],
  [
    "offsite_conversion.fb_pixel_complete_registration",
    "Completed Registration",
  ],
  [
    "offsite_conversion.fb_pixel_custom",
    "Custom pixel events defined by the advertiser",
  ],
  ["offsite_conversion.fb_pixel_initiate_checkout", "Initiates Checkout"],
  ["offsite_conversion.fb_pixel_lead", "Leads"],
  ["offsite_conversion.fb_pixel_purchase", "Purchases"],
  ["offsite_conversion.fb_pixel_search", "Searchs"],
  ["offsite_conversion.fb_pixel_view_content", "Views Content"],
  ["onsite_conversion.flow_complete", "On-Facebook Workflow Completions"],
  ["onsite_conversion.messaging_block", "Blocked Messaging Conversations"],
  [
    "onsite_conversion.messaging_conversation_started_7d",
    "Messaging Conversations Started",
  ],
  ["onsite_conversion.messaging_first_reply", "New Messaging Conversations"],
  ["onsite_conversion.post_save", "Post Saves"],
  ["onsite_conversion.purchase", "On-Facebook Purchases"],
  ["outbound_click", "Outbound Clicks"],
  ["photo_view", "Page Photo Views"],
  ["post", "Post Shares"],
  ["post_reaction", "Post Reactions"],
  ["rsvp", "Event Responses"],
  ["video_view", "3-Second Video Views"],
  ["app_engagement"],
  ["app_story"],
  ["page_engagement"],
  ["page_story"],
  ["post_engagement"],
  ["instagram_profile_engagement"],
  ["post_story"],
  ["offsite_conversion"],
  ["onsite_conversion"],
  ["app_custom_event"],
  ["onsite_web_app_event"],
  ["mobile_app_retention"],
  ["mobile_app_roas"],
  ["offsite_engagement"],
  ["store_visit_with_dwell"],
  ["add_to_cart"],
  ["onsite_app_add_to_cart"],
  ["onsite_web_add_to_cart"],
  ["onsite_web_app_add_to_cart"],
  ["complete_registration"],
  ["purchase"],
  ["onsite_app_purchase"],
  ["onsite_conversion.lead_grouped", "All On-Facebook Leads"],
  ["onsite_web_purchase"],
  ["onsite_web_app_purchase"],
  ["search"],
  ["view_content"],
  ["onsite_app_view_content"],
  ["onsite_web_view_content"],
  ["onsite_web_app_view_content"],
  ["initiate_checkout"],
  ["add_payment_info"],
  ["add_to_wishlist"],
  ["lead", "All offsite leads plus all On-Facebook leads"],
  [
    "leadgen_grouped",
    "On-Facebook leads coming from Messenger and Instant Forms",
  ],
  ["subscribe"],
];

module.exports = { allConversionEvents };
